<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.查看')"
                 fixed
                 @click-left="$router.go(-1)">
    </van-nav-bar>
    <div :style="{paddingTop:'46px',paddingBottom:approvalShow ? '50px' : '0'}"
         class="applyHolidayView">
      <div>
        <div>
          <p>
            <i class="iconfont icon-qingxiujiashenqing"></i>
          </p>
          <div>
            <p>{{ $t('tbm.请休假申请') }}</p>
            <p>{{ $t('tbm.申请时间') }}：{{form.createTime}}</p>
          </div>
        </div>
        <p :style="{color:form.formColor,'font-size':'16px'}">{{form.formStatusName}}</p>
      </div>
      <div>
        <div v-for="(item,index) in dataList"
             :key="index">
          <label>{{item.label}}</label>
          <p>{{form[item.text]}}</p>
        </div>
      </div>
      <filesList :showFile="true"
                 v-show="filesList.length>0"
                 :filesList="filesList"></filesList>
      <history-panel :pid="form.id"
                     :woType="procConf.woCode"
                     :formNo="form.formNo"></history-panel>

      <div v-if="approvalShow">
        <approval v-if="form"
                  class="approvalFixed"
                  :woCodeValue="procConf.woCode"
                  saveUrl="applyHoliday"
                  :businessInfo="form"
                  :detailPageName="$route.name"
                  :customSubmitBtnName="customSubmitBtnName"
                  :processName="procConf.processInstanceName"
                  :taskId="procConf.taskInstanceId">
        </approval>
      </div>

    </div>
  </div>
</template>
<script>
import filesList from '@/views/business/components/filesList';
import historyPanel from '@/views/business/components/historyPanel';
import approval from '@/components/approval/approval';
export default {
  components: {
    filesList,
    historyPanel,
    approval
  },
  data () {
    return {
      customSubmitBtnName: ['同意', '不同意'],
      form: {
        id: '',
        createTime: '',
        formStatusName: '',
        formColor: '',
        userName: '',
        deptName: '',
        holidayName: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        reason: '',
        formNo: ''
      },
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' },
      ],
      dataList: [
        {
          label: '申请人',
          text: 'userName'
        },
        {
          label: '所在部门',
          text: 'deptName'
        },
        {
          label: '休假类型',
          text: 'holidayName'
        },
        {
          label: '开始时间',
          text: 'beginDate'
        },
        {
          label: '结束时间',
          text: 'endDate'
        },
        {
          label: '折合天数',
          text: 'totalDays'
        },
        {
          label: '请假原因',
          text: 'reason'
        }
      ],
      filesList: [],
      procConf: {
        businessKey: '',
        processInstanceName: '请假申请',
        woCode: '', //对应不同的请假类型
        taskInstanceId: '',
        variables: {}, //用于流程条件判断
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
        activityCode: ''
      },
      approvalShow: false
    }
  },
  methods: {
    backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(async res => {
        let backData = res.data.entity;
        if (backData) {
          this.form = backData;
          if (backData.createTime) {
            this.form.createTime = backData.createTime.split(' ')[0];
            this.form.formStatusName = this.formStatus[backData.formStatus].label;
            this.form.formColor = this.formStatus[backData.formStatus].color;
            this.deptAndPost = parseInt(res.data.ext.deptAndPost)

            let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
            let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
            let officeoutReason = this.form.reason;
            if (officeoutReason.length > 5) {
              officeoutReason = officeoutReason.substring(0., 5) + '……'
            }
            this.procConf.processInstanceName = this.procConf.processInstanceName + '-' + deptName + ' ' + userName + '-' + officeoutReason + '-' + this.form.totalDays + '天'

            await this.getApplyWoCode(this.form.holidayType)

          }
        }
      });
    },
    getApplyWoCode (type) {
      //先处理特殊用户
      if (this.deptAndPost === this.TbmCons.deptAndPost.CEO || this.deptAndPost === this.TbmCons.deptAndPost.CEOSENCOND || this.deptAndPost === this.TbmCons.deptAndPost.CHAIRMANSENCODE) {
        this.procConf.woCode = this.TbmCons.leaveType.GroupLeaderLeave
        return false
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_MATERNITY || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PATERNITY) {
        // 生育陪产
        this.procConf.woCode = this.TbmCons.leaveType.MaternityLeave;
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_WEDDING || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FUNERAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FAMILY) {
        // 婚 丧 探亲假
        this.procConf.woCode = this.TbmCons.leaveType.FuneralLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ANNUAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PUBLIC) {
        // 公假 年休假
        this.procConf.woCode = this.TbmCons.leaveType.AnnualLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_LEAVE || type === this.TbmCons.holidayType.HOLIDAY_TYPE_SICK) {
        //事假 病假
        this.procConf.woCode = this.TbmCons.leaveType.SickLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_INJURY) {
        //工伤假
        // this.procConf.woCode= this.TbmCons.leaveType.InjuryLeave
        this.procConf.woCode = 'TbmInjuryLeave'
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ADJUST) {
        //调休假
        this.procConf.woCode = this.TbmCons.leaveType.VacationLeave
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_CHAPERONE) {
        //陪护假
        this.procConf.woCode = this.TbmCons.leaveType.ChaperoneLeave
      }
      if (this.$route.query.type) {
        this.approvalShow = true;
      }
    },
    getFilesList () {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/list',
        data: {
          'entity': {
            'bizId': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.filesList = backData
        }
      });
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    this.backFillEditData();
    this.getFilesList();
  }
}
</script>
<style>
.applyHolidayView > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
}
.applyHolidayView > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(1) > div > p {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(249, 156, 156);
  margin-right: 10px;
}
.applyHolidayView > div:nth-child(1) > div > p i {
  width: 27px;
  height: 27px;
  font-size: 27px;
  color: #fff;
}
.applyHolidayView > div:nth-child(1) > div > div {
  font-size: 18px;
  color: rgb(51, 51, 51);
}
.applyHolidayView > div:nth-child(1) > div > div p:nth-child(2) {
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-top: 5px;
}
.applyHolidayView > div:nth-child(2) {
  background: #fff;
  padding: 0 10px 10px;
}
.applyHolidayView > div:nth-child(2) > div {
  height: 44px;
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(2) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 70px;
}
</style>

